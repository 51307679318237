.language-tabs-wrapper .rs-nav-default .rs-nav-item > .rs-nav-item-content {
    border-radius: 0;
}

.language-tabs-wrapper .rs-nav-item-active {
    background-color: #f0f8ff;
}

    .language-tabs-wrapper .rs-nav-item-active:hover {
        background-color: #cbe7ff;
    }

.language-tabs-wrapper .language span {
    margin: 0px 2px;
    padding: 2px;
}

.language-tabs-wrapper .language .flag {
    height: 22px !important;
    width: 22px !important;
    margin-right: 2px;
}

.language-tabs-wrapper .rs-nav-item-active .language .delete {
    color: red;
    background-color: transparent;
}

.language-tabs-wrapper .rs-nav-item .language .delete:hover {
    color: red;
    background-color: #f7f7fa;
}
