.editor-wrapper {
  min-height: 2em;
  padding: 0.5em;
  cursor: text;
}

.ml5 {
  margin-left: 5px;
}

.label-wrapper {
  display: inline-block;
}

.label-wrapper button {
  background: #fbfbfb;
  color: #888;
  vertical-align: bottom;
}

.label-wrapper button:hover,
.label-wrapper button:focus {
  background: #f3f3f3;
}

.label-popover {
  width: 300px;
}

.grid-popover .rs-row {
  margin: 0.5em 0em;
}

.grid-popover .rs-row button {
  float: right;
}

.grid-popover .label-list {
  height: 100px;
  overflow-y: auto;
}

.grid-popover .label-list label {
  cursor: pointer;
}

.grid-popover .label-list .rs-list-item.selected {
  background-color: #345678;
  color: white;
}

.search-and-replace-highlight {
  background: yellow;
}

.intent-popover p {
  font-size: 1.1em;
}

.input-label {
  border: 1px solid #e5e5ea;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  padding: 4px 11px;
}