html, body {
    height: 100%;
    background-color: rgb(247, 247, 247);
    margin: 0;
    padding: 0;
}

.loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100px;
    margin-left: -230px;
    margin-top: -50px;
    display: block;
    width: 500px;
}

.loader {
    position: relative;
    display: block;
    width: 100%;
    height: 10px;
    margin-top: 34px;
}

/* ooo, css circles*/
.circle {
    display: inline-block;
    position: relative;
    /* key up the animations! */
    -webkit-animation: dotloader 2s infinite;
    -moz-animation: dotloader 2s infinite;
    -ms-animation: dotloader 2s infinite;
    -o-animation: dotloader 2s infinite;
    animation: dotloader 2s infinite;
}

.circle + .circle {
    margin-left: -25px;
    /* staggered effect cause by delay property */
    -webkit-animation: dotloader 2s 0.2s infinite;
    -moz-animation: dotloader 2s 0.2s infinite;
    -ms-animation: dotloader 2s 0.2s infinite;
    -o-animation: dotloader 2s 0.2s infinite;
    animation: dotloader 2s 0.2s infinite;
}

.circle + .circle + .circle {
    margin-left: -25px;
    -webkit-animation: dotloader 2s 0.4s infinite;
    -moz-animation: dotloader 2s 0.4s infinite;
    -ms-animation: dotloader 2s 0.4s infinite;
    -o-animation: dotloader 2s 0.4s infinite;
    animation: dotloader 2s 0.4s infinite;
}

.circle + .circle + .circle + .circle {
    margin-left: -25px;
    -webkit-animation: dotloader 2s 0.6s infinite;
    -moz-animation: dotloader 2s 0.6s infinite;
    -ms-animation: dotloader 2s 0.6s infinite;
    -o-animation: dotloader 2s 0.6s infinite;
    animation: dotloader 2s 0.6s infinite;
}

/* here be the animations matey! */
@keyframes dotloader {
    0%,20% {
        width: 4px;
        height: 4px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        left: 0%;
        background-color: rgba(0, 0, 0, 0);
    }

    30%, 70% {
        width: 8px;
        height: 8px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    80%, 100% {
        width: 4px;
        height: 4px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        left: 100%;
        background-color: rgba(0, 0, 0, 0);
    }
}

@-moz-keyframes dotloader {
    0%,20% {
        width: 4px;
        height: 4px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        left: 0%;
        background-color: rgba(0, 0, 0, 0);
    }

    30%,70% {
        width: 8px;
        height: 8px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    80%,100% {
        width: 4px;
        height: 4px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        left: 100%;
        background-color: rgba(0, 0, 0, 0);
    }
}

@-webkit-keyframes dotloader {
    0%,20% {
        width: 4px;
        height: 4px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        left: 0%;
        background-color: rgba(0, 0, 0, 0);
    }

    30%, 70% {
        width: 8px;
        height: 8px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    80%, 100% {
        width: 4px;
        height: 4px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        left: 100%;
        background-color: rgba(0, 0, 0, 0);
    }
}

@-ms-keyframes dotloader {
    0%,20% {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        left: 0%;
        background-color: rgba(0, 0, 0, 0);
    }

    30%, 70% {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    80%, 100% {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        left: 100%;
        background-color: rgba(0, 0, 0, 0);
    }
}

@-o-keyframes dotloader {
    0%,20% {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        left: 0%;
        background-color: rgba(0, 0, 0, 0);
    }

    30%, 70% {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    80%, 100% {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        left: 100%;
        background-color: rgba(0, 0, 0, 0);
    }
}
